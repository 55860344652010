import { useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { useModal } from "../context/ModalContext";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 18px;
  padding-right: 18px;
  border-bottom: 2px solid #7b1bb4;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
  height: 80px;
`;

const LeftContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;

const CenterContainer = styled.div`
  flex: 3;
  display: flex;
  justify-content: center;
  gap: 32px;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    background-color: white;
    position: absolute;
    top: 80px; /* Below the navbar */
    width: 100%;
    left: 0;
    right: 0;
    border-bottom: 2px solid #7b1bb4;
    display: ${({ $isopen }) => ($isopen ? "flex" : "none")}; /* Mobile behavior */
  }
`;

const RightContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1024px) {
    display: none; /* Hide desktop button on mobile */
  }
`;

const RightButton = styled.button`
  padding: 12px 35px;
  background-color: #7b1bb4;
  &:hover {
    background-color: #5e0891;
  }
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;

  @media (max-width: 1024px) {
    display: none; /* Hide on mobile */
  }
`;

const RightButtonMobile = styled.button`
  padding: 12px 35px;
  background-color: #7b1bb4;
  &:hover {
    background-color: #5e0891;
  }
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;

  @media (min-width: 1024px) {
    display: none; /* Hide on desktop */
  }
`;

const LeftIcon = styled.img`
  width: 80px;
  height: auto;
`;

const NavContainer = styled(Link)`
  padding: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #7b1bb4;
    border-radius: 6px;

    span {
      color: white;
    }
  }
`;

const NavText = styled.span`
  color: #7b1bb4;
  font-size: 1.2rem;
  transition: color 0.3s ease;
`;

// Hamburger icon styles
const Hamburger = styled.div`
  display: none;
  cursor: pointer;
  flex-direction: column;
  gap: 5px;

  div {
    width: 25px;
    height: 3px;
    background-color: #7b1bb4;
  }

  @media (max-width: 1024px) {
    display: flex;
  }
`;

function NavBar() {
  const [isopen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isopen);
  };

  const handleLinkClick = () => {
    setIsOpen(false); // Close the menu when a link is clicked
  };
  
  const { openModal } = useModal();

  return (
    <Container>
      <LeftContainer>
        <LeftIcon src="/CODA.jpeg" alt="CODA LOGO" />
      </LeftContainer>

      <CenterContainer $isopen={isopen}>
        <NavContainer to="home" smooth={true} duration={500} onClick={handleLinkClick}>
          <NavText>Home</NavText>
        </NavContainer>
        <NavContainer to="coda" smooth={true} duration={500} onClick={handleLinkClick}>
          <NavText>CODA</NavText>
        </NavContainer>
        <NavContainer to="about" smooth={true} duration={500} onClick={handleLinkClick}>
          <NavText>About</NavText>
        </NavContainer>
        <NavContainer to="Press Room" smooth={true} duration={500} onClick={handleLinkClick}>
          <NavText>Press</NavText>
        </NavContainer>
        <NavContainer to="partners" smooth={true} duration={500} onClick={handleLinkClick}>
          <NavText>Partners</NavText>
        </NavContainer>
        <NavContainer to="content" smooth={true} duration={500} onClick={handleLinkClick}>
          <NavText>Content</NavText>
        </NavContainer>
        {/* Show Get Coda button when the menu is open */}
        {isopen && 
            <RightButtonMobile onClick={openModal} style={{marginBottom:'15px'}}>Get Coda</RightButtonMobile>
        }
      </CenterContainer>

      <RightContainer>
        <RightButton onClick={openModal}>Get Coda</RightButton>
      </RightContainer>

      {/* Hamburger Menu */}
      <Hamburger onClick={toggleMenu}>
        <div />
        <div />
        <div />
      </Hamburger>
    </Container>
  );
}

export default NavBar;
